import { useEffect, useState } from "react";
import Button from "../bootstrap/Button";
import FormGroup from "../bootstrap/forms/FormGroup";
import Input from "../bootstrap/forms/Input";
import { Client, ClientSupply } from "../../type/client-type";
import { ClientService } from "../../services/clients/clientService";
import Spinner from "../bootstrap/Spinner";
import Tooltips from "../bootstrap/Tooltips";
import ClientSupplyCard from "../cards/ClientSupplyCard";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { showErrors, verifyClass } from "../../utils/forms/VerifyForms";
import PhoneInput, { CountryData } from "react-phone-input-2";
import Select from "../bootstrap/forms/Select";
import { useLeadList, useOperationTypeList, useOriginList, useProductTypeList, useUserList } from "../../hooks/useListData";
import { Operation, operationForValues } from "../../type/operation-type";
import InputGroup from "../bootstrap/forms/InputGroup";
import { CNAEModalSelector } from "../selectors/cnae/CNAEModalSelector";
import {Product, ProductHasCommission, ProductHasService, ProductType} from "../../type/product-type";
import { Business } from "../../type/business-type";
import { ProductService } from "../../services/products/productService";
import Checks, { ChecksGroup } from "../bootstrap/forms/Checks";
import { ValidateDNICIFField } from "../../utils/forms/ValidateDNICIFField";
import { ValidateIBANField } from "../../utils/forms/ValidateIBANField";
import { ValidatePhone } from "../../utils/forms/ValidatePhone";
import { ValidateCUPS } from "../../utils/forms/ValidateCups";
import { BusinessService } from "../../services/business/businessService";
import { OperationService } from "../../services/operations/operationService";
import useHandleErrors from "../../hooks/useHandleErrors";
import Icon from "../icon/Icon";
import Textarea from "../bootstrap/forms/Textarea";
import { usePrivilege } from "../priviledge/PriviledgeProvider";
import Label from "../bootstrap/forms/Label";
import { UserService } from "../../services/users/userService";
import AsyncImg from "../AsyncImg";
import { Fee } from "../../type/fee-type";
import { FeeService } from "../../services/fees/feeService";
import CustomSelect from "../selectors/general/CustomSelect";
import { SingleValue } from "react-select";
import { User } from "../../type/user-type";

type OperationFormProps = {
    operationId?: string;
    clientId: string;
    onOperationCreate?: (operation: any) => void;
    onOperationEdit?: (operation: any) => void;
}

// Add required custom validatios to Yup
Yup.addMethod(Yup.string, 'isValidDNICIF', ValidateDNICIFField);
Yup.addMethod(Yup.string, 'isValidIBAN', ValidateIBANField);
Yup.addMethod(Yup.string, 'isValidPhone', ValidatePhone);
Yup.addMethod(Yup.string, 'isValidCUPS', ValidateCUPS);

// Create validationSchema
const validationSchema = Yup.object({
    client: Yup.string().required('El cliente es obligatorio'),
    clientName: Yup.string().required('El nombre del cliente es obligatorio'),
    // @ts-ignore
    clientNIF: Yup.string().required('El NIF del cliente es obligatorio').isValidDNICIF('El NIF del cliente no es válido'),
    // @ts-ignore
    clientIBAN: Yup.string().required('El IBAN del cliente es obligatorio para dar de alta el contrato').isValidIBAN('El IBAN del cliente no es válido'),
    clientAddress: Yup.string().required('La dirección del cliente es obligatoria'),
    clientPostalCode: Yup.string().required('El código postal del cliente es obligatorio').matches(/^\d{5}$/, 'El código postal debe tener 5 dígitos'),
    clientTown: Yup.string().required('La población del cliente es obligatoria'),
    clientProvince: Yup.string().required('La provincia del cliente es obligatoria'),
    // @ts-ignore
    clientPhone1: Yup.string().required('El teléfono del cliente es obligatorio').isValidPhone('El teléfono no es válido'),
    // @ts-ignore
    clientPhone2: Yup.string().nullable().isValidPhone('El teléfono no es válido').transform((_, value) => {
        return value === "" ? null : value;
    }),
    clientEmail: Yup.string().nullable().email('El correo electrónico no es válido'),

    supplyAddress: Yup.string().required('La dirección del suministro es obligatoria'),
    supplyPostalCode: Yup.string().required('El código postal del suministro es obligatorio').matches(/^\d{5}$/, 'El código postal debe tener 5 dígitos'),
    supplyTown: Yup.string().required('La población del suministro es obligatoria'),
    supplyProvince: Yup.string().required('La provincia del suministro es obligatoria'),
    // @ts-ignore
    supplyCups: Yup.string().required('El CUPS del suministro es obligatorio').isValidCUPS('El CUPS no es válido'),

    operationType: Yup.string().required('El tipo de contrato es obligatorio'),
    operationLead: Yup.string().required('El tipo de trámite es obligatorio'),
    operationOrigin: Yup.string().required('El origen del lead es obligatorio'),
    operationFor: Yup.string().required('El tipo de cliente es obligatorio'),
    operationComment: Yup.string().required('El comentario es obligatorio'),
    operationCNAE: Yup.string().required('El CNAE es obligatorio').length(4, "El CNAE debe tener 4 dígitos"),
    product: Yup.string().required('El producto es obligatorio'),
    productType: Yup.string().required('El tipo de producto es obligatorio'),
    productBusiness: Yup.string().required('La comercializadora es obligatoria'),
    productPowerP1: Yup.number().typeError('Debe ser un número').nullable().transform((_, value) => {
        return value === "" ? null : Number(value);
    }),
    productPowerP2: Yup.number().typeError('Debe ser un número').nullable().transform((_, value) => {
        return value === "" ? null : Number(value);
    }),
    productPowerP3: Yup.number().typeError('Debe ser un número').nullable().transform((_, value) => {
        return value === "" ? null : Number(value);
    }),
    productPowerP4: Yup.number().typeError('Debe ser un número').nullable().transform((_, value) => {
        return value === "" ? null : Number(value);
    }),
    productPowerP5: Yup.number().typeError('Debe ser un número').nullable().transform((_, value) => {
        return value === "" ? null : Number(value);
    }),
    productPowerP6: Yup.number().typeError('Debe ser un número').nullable().transform((_, value) => {
        return value === "" ? null : Number(value);
    }),
    productConsumption: Yup.number().typeError('Debe ser un número').nullable().transform((_, value) => {
        return value === "" ? null : Number(value);
    }),
    productServices: Yup.array().of(Yup.string())

})

const validationSchemaEdit = Yup.object({
    ...validationSchema.fields,
    operationUser: Yup.string().required('El usuario es obligatorio'),
    operationAgent: Yup.string().required('El agente es obligatorio'),
})


const CompleteOperationForm: React.FC<OperationFormProps> = ({ operationId, clientId, onOperationCreate, onOperationEdit }) => {

    // SERVICES
    const clientService = new ClientService()
    const productService = new ProductService()
    const feeService = new FeeService();
    const businessService = new BusinessService()
    const operationService = new OperationService()
    const userService = new UserService();

    // STATE
    const [client, setClient] = useState<Client | null>(null)
    const [operation, setOperation] = useState<Operation | null>(null)

    const [usersSearchList, setUsersSearchList] = useState<any>();

    const [operationAgent, setOperationAgent] = useState<any | null>(null)
    const [operationUser, setOperationUser] = useState<any | null>(null)

    const [refreshOperationUser, setRefreshOperationUser] = useState<number>(0)
    const [refreshOperationAgent, setRefreshOperationAgent] = useState<number>(0)

    const [supplyPoints, setSupplyPoints] = useState<any[] | null>(null)
    const [isLoadingSupplyPoints, setIsLoadingSupplyPoints] = useState<boolean>(false)
    const operationTypeList = useOperationTypeList();
    const originList = useOriginList();
    const leadList = useLeadList();
    const productTypeList = useProductTypeList();
    const usersDefaultList = useUserList({ filter_order : [{field: "created_at", order: 'DESC'}] , all: true});
    const [CNAESelectorVisible, setCNAESelectorVisible] = useState<boolean>(false);
    const [productList, setProductList] = useState<Product[]>([]);
    const [feeList , setFeeList] = useState<Fee[]>([]);
    const [servicesList, setServicesList] = useState<ProductHasService[]>([]);
    const [businessList, setBusinessList] = useState<Business[]>([]);
    const [loadingForm, setLoadingForm] = useState<boolean>(false);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const { handleErrors } = useHandleErrors();

    // PRIVILEGES
    const { userCan } = usePrivilege();

    const formik = useFormik({
        validationSchema: operationId ? validationSchemaEdit : validationSchema,
        initialValues: {
            // Client fields
            client: '',
            clientName: '',
            clientNIF: '',
            clientIBAN: '',
            clientAddress: '',
            clientPostalCode: '',
            clientTown: '',
            clientProvince: '',
            clientPhone1: '',
            clientPhone2: '',
            clientEmail: '',
            // Supply fields
            supplyAddress: '',
            supplyPostalCode: '',
            supplyTown: '',
            supplyProvince: '',
            supplyCups: '',

            // Operation fields
            operationType: '',
            operationLead: '',
            operationOrigin: '',
            operationFor: '',
            operationCNAE: '',
            operationComment: '',
            operationUser: '',
            operationAgent : '',

            // Product fields
            product: '',
            productType: '',
            productBusiness: '',
            productFee: '',
            productPowerP1: '',
            productPowerP2: '',
            productPowerP3: '',
            productPowerP4: '',
            productPowerP5: '',
            productPowerP6: '',
            productConsumption: '',
            productServices: []
        },
        onSubmit: async (values) => {

            // Cast values for transform to null the empty objects
            let testValues = validationSchema.cast(values)
            setLoadingForm(true)
            
            let response: any = {}

            if(operation) {
                let valuesToSend = {
                    ...testValues,
                    operation: operation.id
                }
                response = await (await (operationService.editOperationV2(valuesToSend))).getResponseData();
            } else {
                response = await (await (operationService.createOperationV2(testValues))).getResponseData();
            }

            setLoadingForm(false);

            if (response.success) {
                onOperationCreate && onOperationCreate(response.data);
                onOperationEdit && onOperationEdit(response.data);
            } else {
                handleErrors(response);
            }

        }
    })

    const _getSuplyPoints = async () => {

        if (clientId == null) return

        setIsLoadingSupplyPoints(true)

        let response = (await clientService.getClientCups(clientId)).getResponseData()

        setIsLoadingSupplyPoints(false)

        if (response.success) {
            setSupplyPoints(response.data)
        }
    }


    // ACTIONS
    const _getClient = async () => {
        if (clientId == null) return
        let response = (await clientService.getClientById(clientId)).getResponseData()
        if (response.success) {
            setClient(response.data)
            formik.setFieldValue('client', response.data.id)
        }
    }

    const _getOperation = async () => {
        if (operationId == null) return
        let response = (await operationService.getOperationById(operationId)).getResponseData()
        if (response.success) {
            setLoadingData(true)
            setOperation(response.data)
        }
    }

    const _getUserById = async (id? : string , type? : string) => {
        if (id == null) return
        let response = (await userService.getUserById(id)).getResponseData()
        if (response.success) {

            if (type === 'agent') { 
                setOperationAgent(response.data) 
                setRefreshOperationAgent( refreshOperationAgent + 1)
            } else {
                setOperationUser(response.data)
                setRefreshOperationUser( refreshOperationUser + 1)
            }
        }
    }

    const onInputChange = async (inputValue: string) => {
        if (inputValue.length < 3) {
            return inputValue;
        }

        const response = await (await (new UserService()).getUsers({ filter_filters: { search_text: inputValue } })).getResponseData();

        if (response.success) {
            let usersList = response.data.users.map((user: User) => {
                return {
                    value: user.id ,
                    label: user.name, 
                }
            })
            setUsersSearchList(usersList);
        }

        return inputValue;
    }

    const _maxOfPowers = (): number => {
        let max = 0;

        if (formik.values.productPowerP1) {
            max = parseFloat(formik.values.productPowerP1)
        }
        if (formik.values.productPowerP2 && parseFloat(formik.values.productPowerP2) > max) {
            max = parseFloat(formik.values.productPowerP2)
        }
        if (formik.values.productPowerP3 && parseFloat(formik.values.productPowerP3) > max) {
            max = parseFloat(formik.values.productPowerP3)
        }
        if (formik.values.productPowerP4 && parseFloat(formik.values.productPowerP4) > max) {
            max = parseFloat(formik.values.productPowerP4)
        }
        if (formik.values.productPowerP5 && parseFloat(formik.values.productPowerP5) > max) {
            max = parseFloat(formik.values.productPowerP5)
        }
        if (formik.values.productPowerP6 && parseFloat(formik.values.productPowerP6) > max) {
            max = parseFloat(formik.values.productPowerP6)
        }

        return max;
    }

    const _handleGetProductList = async () => {

        formik.setFieldValue('product', '') // Hay que ver si dejamos esto.
        setProductList([])

        if (!formik.values.productType || !formik.values.productBusiness) {
            return;
        }

        let response = (await productService.getProducts({
            filter_filters: {
                consumption: formik.values.productConsumption,
                power: _maxOfPowers(),
                business: formik.values.productBusiness,
                fee: formik.values.productFee,
                productType: formik.values.productType,
                operationType: formik.values.operationType,
                leads: [formik.values.operationLead],
                clientType: formik.values.operationFor

            }
        })).getResponseData()

        if (response.success) {
            // Get the max power selected in formik
            let maxPower = _maxOfPowers();
            
            // Loop products to add the price to each one depending of the commissions of each one
            let products = response.data.products.map((product: Product) => {

                let foundVariation: ProductHasCommission | null = product.commissions.find(
                    (commission: ProductHasCommission) =>
                      (commission.consumptionFrom !== null && commission.consumptionFrom !== 0  && commission.consumptionTo !== null &&
                        commission.consumptionFrom <= parseFloat(formik.values.productConsumption) &&
                        commission.consumptionTo >= parseFloat(formik.values.productConsumption) &&
                        commission.powerFrom <= maxPower && commission.powerTo >= maxPower) ||
                      (commission.powerFrom <= maxPower && commission.powerTo >= maxPower)
                  ) || null;

                if(foundVariation) {
                    // @ts-ignore
                    product.points = foundVariation.points
                }

                return product
            });
            
            setProductList(products)

            if(operation && operation.products.length > 0) {
                let product = operation.products[0]
                // wait to load services for select
                setTimeout(() => {
                    formik.setFieldValue('product', product?.product?.id)
                }, 500);
            }
        }
    }

    const _handleGetBusinessByProductType = async () => {

        setProductList([])

        if (!formik.values.productType) {
            return;
        }

        let response = (await businessService.getBusinesses({
            filter_filters: {
                productType: formik.values.productType
            }
        })).getResponseData()

        if (response.success) {
            setBusinessList(response.data.businesses)
        }
    }

    const _handleGetFeesByBusinessAndProductType = async () => {

        setFeeList([])
        formik.setFieldValue('productFee', '')

        if (!formik.values.productType || !formik.values.productBusiness) {
            return;
        }

        let response = (await feeService.getFees({
            filter_filters: {
                business: formik.values.productBusiness,
                productType: formik.values.productType,
            }
        })).getResponseData()

        if (response.success) {
            setFeeList(response.data.fees)
        }
    }



    // When a product is selected, we retrieve all services availables for that product.
    const _handleGetServiceList = async () => {

        setServicesList([]);
        formik.setFieldValue('productServices', []);

        if (!formik.values.product) {
            setLoadingData(false)
            return;
        }

        let response = (await productService.getProductAvailableServices(formik.values.product)).getResponseData()

        if (response.success) {
            setServicesList(response.data)
            setLoadingData(false)
            if(operation && operation.products.length > 0) {
                let product = operation.products[0]
                // wait to load services for select
                setTimeout(() => {
                    formik.setFieldValue('productServices', product?.services.map((service: any) => service.service.id))
                }, 500);
            }
        }

    }

    const _handleUnSelectSupply = () => {
        setSupplyPoints(null)
    }

    const _handleSelectSupply = (supply: ClientSupply) => {

        formik.setFieldValue('supplyAddress', supply?.products[0]?.address)
        formik.setFieldValue('supplyPostalCode', supply?.products[0]?.postalcode)
        formik.setFieldValue('supplyTown', supply?.products[0]?.town)
        formik.setFieldValue('supplyProvince', supply?.products[0]?.province)
        formik.setFieldValue('supplyCups', supply?.products[0]?.cups)
        formik.setFieldValue('operationType', supply?.operationType?.id)
        formik.setFieldValue('operationLead', supply?.lead?.id)
        formik.setFieldValue('operationOrigin', supply?.origin?.id)
        formik.setFieldValue('operationFor', supply?.operationFor)
        formik.setFieldValue('operationCNAE', supply?.cnae)

        formik.setFieldValue('productType', (supply?.products[0]?.product?.productType as ProductType)?.id || '')
        formik.setFieldValue('productBusiness', (supply?.products[0]?.product?.business as Business)?.id || '')
        formik.setFieldValue('productFee', (supply?.products[0]?.product?.fee as Fee)?.id || '')
        formik.setFieldValue('productPowerP1', supply?.products[0]?.powerP1 || '')
        formik.setFieldValue('productPowerP2', supply?.products[0]?.powerP2 || '')
        formik.setFieldValue('productPowerP3', supply?.products[0]?.powerP3 || '')
        formik.setFieldValue('productPowerP4', supply?.products[0]?.powerP4 || '')
        formik.setFieldValue('productPowerP5', supply?.products[0]?.powerP5 || '')
        formik.setFieldValue('productPowerP6', supply?.products[0]?.powerP6 || '')
        formik.setFieldValue('productConsumption', supply?.products[0]?.consumption || '')
    }

    const _handleClientChange = () => {
        formik.setFieldValue('clientName', client?.name)
        formik.setFieldValue('clientNIF', client?.nif)
        formik.setFieldValue('clientIBAN', client?.iban)
        formik.setFieldValue('clientAddress', client?.address)
        formik.setFieldValue('clientPostalCode', client?.postalCode)
        formik.setFieldValue('clientTown', client?.locality)
        formik.setFieldValue('clientProvince', client?.province)
        formik.setFieldValue('clientPhone1', client?.phone1)
        formik.setFieldValue('clientPhone2', client?.phone2 || '')
        formik.setFieldValue('clientEmail', client?.email)
    }

    // Handle operation selected
    const _handleOperationChange = () => {
        formik.setFieldValue('client', operation?.client.id)
        formik.setFieldValue('clientName', operation?.clientName)
        formik.setFieldValue('clientNIF', operation?.clientDni)
        formik.setFieldValue('clientIBAN', operation?.iban)
        formik.setFieldValue('clientAddress', operation?.clientAddress)
        formik.setFieldValue('clientPostalCode', operation?.clientPostalCode)
        formik.setFieldValue('clientTown', operation?.locality)
        formik.setFieldValue('clientProvince', operation?.province)
        formik.setFieldValue('clientPhone1', operation?.clientPhone)
        formik.setFieldValue('clientPhone2', operation?.clientPhone2 || '')
        formik.setFieldValue('clientEmail', operation?.clientEmail)
        if(operation?.products.length > 0) {
            const product = operation?.products[0]
            formik.setFieldValue('supplyAddress', product?.address)
            formik.setFieldValue('supplyPostalCode', product?.postalcode)
            formik.setFieldValue('supplyTown', product?.town)
            formik.setFieldValue('supplyProvince', product?.province)
            formik.setFieldValue('supplyCups', product?.cups)
            formik.setFieldValue('productType', product?.product?.productType?.id)
            formik.setFieldValue('productBusiness', product?.business?.id)
            formik.setFieldValue('productFee', product?.product.fee?.id)
            formik.setFieldValue('productPowerP1', product?.powerP1)
            formik.setFieldValue('productPowerP2', product?.powerP2)
            formik.setFieldValue('productPowerP3', product?.powerP3)
            formik.setFieldValue('productPowerP4', product?.powerP4)
            formik.setFieldValue('productPowerP5', product?.powerP5)
            formik.setFieldValue('productPowerP6', product?.powerP6)
            formik.setFieldValue('productConsumption', product?.consumption)
        }
        formik.setFieldValue('operationType', operation?.operationType?.id)
        formik.setFieldValue('operationLead', operation?.lead?.id)
        formik.setFieldValue('operationOrigin', operation?.origin?.id)
        formik.setFieldValue('operationFor', operation?.operationFor)
        formik.setFieldValue('operationCNAE', operation?.cnae)
        formik.setFieldValue('operationComment', operation?.operationComment)
        formik.setFieldValue('operationUser', operation?.user?.id)
        formik.setFieldValue('operationAgent', operation?.agent?.id)
    }

    const _handleCopyClientAddressToSupply = () => {
        if(operationId && client == null) {
            formik.setFieldValue('supplyAddress', operation?.clientAddress)
            formik.setFieldValue('supplyPostalCode', operation?.clientPostalCode)
            formik.setFieldValue('supplyTown', operation?.locality)
            formik.setFieldValue('supplyProvince', operation?.province)
        } else {
            formik.setFieldValue('supplyAddress', client?.address)
            formik.setFieldValue('supplyPostalCode', client?.postalCode)
            formik.setFieldValue('supplyTown', client?.locality)
            formik.setFieldValue('supplyProvince', client?.province)
        }
    }

    // ON START
    useEffect(() => {
        if(operationId) {
            _getOperation()
        } else {
            _getClient()
        }
    }, [])

    // ON CLIENT HAS CHANGED
    useEffect(() => {
        _handleClientChange()
    }, [client])

    // ON OPERATION HAS CHANGED
    useEffect(() => {

        if (operation === null) return

        _handleOperationChange()
        _getUserById(operation?.user?.id , 'user')
        _getUserById(operation?.agent?.id , 'agent')
    }, [operation])

    // ON OPERATION AGENT HAS CHANGED
    useEffect(() => {
        if (usersDefaultList.length > 0) {
            setUsersSearchList(usersDefaultList);
        }
    }, [usersDefaultList]);

    // ON PRODUCT FEATURES HAS CHANGED
    useEffect(() => {
        _handleGetProductList();
    }, [
        formik.values.operationLead,
        formik.values.operationType,
        formik.values.operationFor,
        formik.values.productType,
        formik.values.productBusiness,
        formik.values.productFee,
        formik.values.productConsumption,
        formik.values.productPowerP1,
        formik.values.productPowerP2,
        formik.values.productPowerP3,
        formik.values.productPowerP4,
        formik.values.productPowerP5,
        formik.values.productPowerP6
    ])

    // ON PRODUCT SELECTED
    useEffect(() => {
        _handleGetServiceList();
    }, [formik.values.product])

    // ON PRODUCTTYPE SELECTED
    useEffect(() => {
        _handleGetBusinessByProductType();
    }, [formik.values.productType])

    useEffect(() => {
        _handleGetFeesByBusinessAndProductType();
    }, [formik.values.productType, formik.values.productBusiness])

    return (
        <>
            {/** Selección de datos de suministro anterior para rellenar los datos automáticamente */}
            <div className="row">
                <div className="col-12">
                    <h4>
                        <span className="me-4">Desde un punto de suministro anterior</span>
                        <Tooltips title="Buscar puntos de suministro del cliente">
                            <Button icon="Search" color="primary" isLight onClick={_getSuplyPoints}></Button>
                        </Tooltips>
                    </h4>
                </div>
            </div>
            {supplyPoints && (
                <div className="row">
                    <div className="col-12 text-center p-4">
                        {isLoadingSupplyPoints && (<Spinner />)}
                        {!isLoadingSupplyPoints && (
                            <>
                                {supplyPoints.length === 0 && (<h4 className="m-4 text-muted">No se han encontrado puntos de suministro para este cliente</h4>)}

                                {supplyPoints.length > 0 && supplyPoints.map((supplyPoint: any) => <ClientSupplyCard key={supplyPoint.id} supply={supplyPoint} onClick={_handleSelectSupply} />)}

                                <Button color="info" isLight onClick={_handleUnSelectSupply} icon="close">Cerrar Selector de Suministro</Button>
                            </>
                        )}
                    </div>
                </div>
            )}

            {/** Formulario de creación de contrato */}
            <div className="row mt-4">
                <div className="col-12">
                    <div className="p-3 border rounded mb-3">
                        <div className="row">
                            <div className="col-md-12">
                                <h4>Datos del cliente: </h4>
                            </div>
                            <div className="col-md-4 mb-2">
                                <FormGroup label="Nombre Completo" requiredInputLabel>
                                    <Input type="text" name="clientName" size={'sm'} className={verifyClass(formik, 'clientName')} value={formik.values.clientName} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    {showErrors(formik, 'clientName')}
                                </FormGroup>
                            </div>
                            <div className="col-md-4 mb-2">
                                <FormGroup label="DNI/CIF" requiredInputLabel>
                                    <Input type="text" name="clientNIF" size={'sm'} className={verifyClass(formik, 'clientNIF')} value={formik.values.clientNIF} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    {showErrors(formik, 'clientNIF')}
                                </FormGroup>
                            </div>
                            <div className="col-md-4 mb-2">
                                <FormGroup label="IBAN" requiredInputLabel>
                                    <Input type="text" name="clientIBAN" size={'sm'} className={verifyClass(formik, 'clientIBAN')} value={formik.values.clientIBAN} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    {showErrors(formik, 'clientIBAN')}
                                </FormGroup>
                            </div>
                            <div className="col-md-5 mb-2">
                                <FormGroup label="Dirección" requiredInputLabel>
                                    <Input type="text" name="clientAddress" size={'sm'} className={verifyClass(formik, 'clientAddress')} value={formik.values.clientAddress} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    {showErrors(formik, 'clientAddress')}
                                </FormGroup>
                            </div>
                            <div className="col-md-2 mb-2">
                                <FormGroup label="Código Postal" requiredInputLabel>
                                    <Input type="text" name="clientPostalCode" size={'sm'} className={verifyClass(formik, 'clientPostalCode')} value={formik.values.clientPostalCode} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    {showErrors(formik, 'clientPostalCode')}
                                </FormGroup>
                            </div>
                            <div className="col-md-3 mb-2">
                                <FormGroup label="Población" requiredInputLabel>
                                    <Input type="text" name="clientTown" size={'sm'} className={verifyClass(formik, 'clientTown')} value={formik.values.clientTown} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    {showErrors(formik, 'clientTown')}
                                </FormGroup>
                            </div>
                            <div className="col-md-2 mb-2">
                                <FormGroup label="Provincia" requiredInputLabel>
                                    <Input type="text" name="clientProvince" size={'sm'} className={verifyClass(formik, 'clientProvince')} value={formik.values.clientProvince} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    {showErrors(formik, 'clientProvince')}
                                </FormGroup>
                            </div>
                            <div className="col-md-3 mb-2">
                                <FormGroup label="Teléfono 1" requiredInputLabel>
                                    <PhoneInput
                                        country={'es'}
                                        onlyCountries={['es']}
                                        autoFormat={false}
                                        countryCodeEditable={false}
                                        onChange={(phone: string) => {
                                            formik.setFieldValue('clientPhone1', "+" + phone);
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.clientPhone1}
                                        inputClass={verifyClass(formik, 'clientPhone1')} />
                                    {showErrors(formik, 'clientPhone1')}
                                </FormGroup>

                            </div>
                            <div className="col-md-3 mb-2">
                                <FormGroup label="Teléfono 2">
                                    <PhoneInput
                                        country={'es'}
                                        onlyCountries={['es']}
                                        placeholder="Opcional"
                                        autoFormat={false}
                                        countryCodeEditable={false}
                                        onChange={(phone: string, data: CountryData) => {

                                            if (phone.length === 0) {
                                                formik.setFieldValue('clientPhone2', "");
                                            }
                                            if (phone == data.dialCode) {
                                                formik.setFieldValue('clientPhone2', "");
                                            }
                                            else {
                                                let formattedPhone = "+" + phone;
                                                formik.setFieldValue('clientPhone2', formattedPhone);
                                            }
                                        }}
                                        value={formik.values.clientPhone2} inputClass={verifyClass(formik, 'clientPhone2')} />
                                    {showErrors(formik, 'clientPhone2')}
                                </FormGroup>
                            </div>
                            <div className="col-md-6 mb-2">
                                <FormGroup label='Correo electrónico'>
                                    <Input
                                        type={'email'}
                                        className={verifyClass(formik, 'clientEmail')}
                                        id='clientEmail'
                                        value={formik.values.clientEmail}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {showErrors(formik, 'clientEmail')}
                                </FormGroup>
                            </div>
                            {/* 
                                        <div className="col-md-12 mt-2 p-2">
                                            <Alert color="primary" isLight>
                                                <p className="m-0"><Icon icon="Info"/> Si el cliente es de tipo empresa y tiene un representante, se recuperará de los datos del cliente.</p>
                                            </Alert>
                                        </div>
                                    */}
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="p-3 border rounded mb-3">
                        <div className="row">
                            <div className="col-md-8">
                                <h4>Dirección del suministro: </h4>
                            </div>
                            <div className="col-md-4 text-end">
                                <Tooltips title="Copiar dirección del cliente">
                                    <Button color="primary" isLight icon="Assignment" onClick={_handleCopyClientAddressToSupply} />
                                </Tooltips>
                            </div>
                            <div className="col-md-5 mb-2">
                                <FormGroup label="Dirección" requiredInputLabel>
                                    <Input type="text" name="supplyAddress" size={'sm'} className={verifyClass(formik, 'supplyAddress')} value={formik.values.supplyAddress} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    {showErrors(formik, 'supplyAddress')}
                                </FormGroup>
                            </div>
                            <div className="col-md-2 mb-2">
                                <FormGroup label="Código Postal" requiredInputLabel>
                                    <Input type="text" name="supplyPostalCode" size={'sm'} className={verifyClass(formik, 'supplyPostalCode')} value={formik.values.supplyPostalCode} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    {showErrors(formik, 'supplyPostalCode')}
                                </FormGroup>
                            </div>
                            <div className="col-md-3 mb-2">
                                <FormGroup label="Población" requiredInputLabel>
                                    <Input type="text" name="supplyTown" size={'sm'} className={verifyClass(formik, 'supplyTown')} value={formik.values.supplyTown} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    {showErrors(formik, 'supplyTown')}
                                </FormGroup>
                            </div>
                            <div className="col-md-2 mb-2">
                                <FormGroup label="Provincia" requiredInputLabel>
                                    <Input type="text" name="supplyProvince" size={'sm'} className={verifyClass(formik, 'supplyProvince')} value={formik.values.supplyProvince} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    {showErrors(formik, 'supplyProvince')}
                                </FormGroup>
                            </div>
                            <div className="col-md-12 mb-2">
                                <FormGroup label="Cups" requiredInputLabel>
                                    <Input type="text" name="supplyCups" size={'sm'} className={verifyClass(formik, 'supplyCups')} value={formik.values.supplyCups} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    {showErrors(formik, 'supplyCups')}
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-12">
                    <div className="p-3 border rounded mb-3">
                        <div className="row">
                            <div className="col-md-12">
                                <h4>Datos del contrato: </h4>
                            </div>
                            <div className="col-md-2 mb-2">
                                <FormGroup id='type' label='Tipo de contrato' requiredInputLabel>
                                    <Select id='SelectOperationType' required ariaLabel='Seleccionar tipo de contrato' placeholder='Elegir...' onChange={(e: any) => formik.setFieldValue('operationType', e.target.value)} value={formik.values.operationType} list={operationTypeList} onBlur={formik.handleBlur} className={verifyClass(formik, 'operationType')} />
                                    {showErrors(formik, 'operationType')}
                                </FormGroup>
                            </div>
                            <div className="col-md-3 mb-2">
                                <FormGroup id='lead' label='Tipo de trámite' requiredInputLabel>
                                    <Select id='SelectLead' required ariaLabel='Elegir Tipo de Trámite' placeholder='Elegir...' onChange={(e: any) => formik.setFieldValue('operationLead', e.target.value)} value={formik.values.operationLead} list={leadList} onBlur={formik.handleBlur} className={verifyClass(formik, 'operationLead')} />
                                    {showErrors(formik, 'operationLead')}
                                </FormGroup>
                            </div>
                            <div className="col-md-2 mb-2">
                                <FormGroup label='Origen Lead' requiredInputLabel>
                                    <Select id='SelectOrigin' required ariaLabel='Elegir Origen Lead' placeholder='Elegir...' onChange={(e: any) => formik.setFieldValue('operationOrigin', e.target.value)} value={formik.values.operationOrigin} list={originList} onBlur={formik.handleBlur} className={verifyClass(formik, 'operationOrigin')} />
                                    {showErrors(formik, 'operationOrigin')}
                                </FormGroup>
                            </div>

                            <div className="col-md-3 mb-2">
                                <FormGroup id='operation_for' label='Tipo de cliente' requiredInputLabel >
                                    <Select id='SelectOperationFor' required ariaLabel='Elegir tipo de cliente' placeholder='Elegir...' onChange={(e: any) => formik.setFieldValue('operationFor', e.target.value)} value={formik.values.operationFor} list={operationForValues} onBlur={formik.handleBlur} className={verifyClass(formik, 'operationFor')} />
                                    {showErrors(formik, 'operationFor')}
                                </FormGroup>
                            </div>
                            <div className="col-md-2 mb-2">
                                <FormGroup id='operationCNAE' label='CNAE' requiredInputLabel >
                                    <InputGroup className={verifyClass(formik, 'operationCNAE')}>
                                        <Input id='operationCNAE' readOnly value={formik.values.operationCNAE} onBlur={formik.handleBlur} />
                                        <Tooltips title="Buscar CNAE">
                                            <Button type="button" color="primary" icon="Search" onClick={() => {
                                                setCNAESelectorVisible(true);
                                            }}></Button>
                                        </Tooltips>
                                    </InputGroup>
                                    {showErrors(formik, 'operationCNAE')}
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                </div>

                { (operation !== null) && (userCan('assign_to_agents', 'operations') || userCan('assign_to_user', 'operations')) ?
                                    (
                                        <div className="col-12">
                                            <div className="p-3 border rounded mb-3">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h4>Usuarios Asignados:</h4>
                                                    </div>
                                                        <div className="col-md-6 mb-2">
                                                            <div className="row">
                                                                <Label className="col-3-custom d-flex align-items-end">Usuario Asignado:</Label> 
                                                                <div className="user-avatar me-0 col-1 d-flex justify-content-center align-items-start">
                                                                    <AsyncImg id={operationUser?.profileImg?.id} key={operationUser?.name}/>
                                                                </div> 
                                                                <Label className="col-6 d-flex align-items-end">
                                                                    {operationUser?.name}
                                                                </Label> 
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 mb-2">
                                                            <div className="row">
                                                                <Label className="col-3 d-flex align-items-end">Agente Asignado:</Label> 
                                                                <div className="user-avatar me-0 col-1 d-flex justify-content-center align-items-start">
                                                                    <AsyncImg id={operationAgent?.profileImg?.id} key={operationAgent?.name}/>
                                                                </div>
                                                                <Label className="col-6 d-flex align-items-end">
                                                                    {operationAgent?.name}
                                                                </Label> 
                                                            </div>
                                                        </div>
                                                        <div className={`mb-2 ${userCan('assign_to_user', 'operations') ? userCan('assign_to_agents', 'operations') ? 'col-md-6' : 'col-md-12' : 'd-none'}`}>
                                                            <div className="row">
                                                                <FormGroup id='operationUser' label='Cambiar Por' requiredInputLabel className={`${formik.values.operationUser !== operationUser?.id ? 'col-10' : 'col-12'}`}>
                                                                    <CustomSelect id='SelectUser' customClass="col-12" required placeholder='Elegir o buscar Usuario ...' options={usersSearchList} is_multi={false} key={refreshOperationUser} onInputChange={onInputChange}
                                                                    onChangeSingle={(newValue: SingleValue<any>) => formik.setFieldValue('operationUser', newValue.value)} defaultValue={{value: operationUser?.id, label: operationUser?.name}}/>
                                                                    {showErrors(formik, 'operationUser')}
                                                                </FormGroup>
                                                                <div className={`d-flex align-items-end ${formik.values.operationUser !== operationUser?.id ? 'col-2' : 'd-none'}`}>
                                                                    <Tooltips title="Resetear a usuario asignado">
                                                                        <Button
                                                                            color="danger"
                                                                            isLight
                                                                            icon="Clear"
                                                                            onClick={() => {
                                                                                formik.setFieldValue('operationUser', operationUser?.id)
                                                                                setRefreshOperationUser( refreshOperationUser + 1)
                                                                            }}
                                                                        />
                                                                    </Tooltips>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`mb-2 ${userCan('assign_to_agents', 'operations') ? userCan('assign_to_user', 'operations') ? 'col-md-6' : 'col-md-12' : 'd-none'}`}>
                                                            <div className="row">
                                                                <FormGroup id='operationAgent' label='Cambiar Por' requiredInputLabel className={`${formik.values.operationAgent !== operationAgent?.id ? 'col-10' : 'col-12'}`}>
                                                                    <CustomSelect id='operationAgent' customClass="col-12" required placeholder='Elegir o buscar Agente ...' options={usersSearchList} is_multi={false} key={refreshOperationAgent} onInputChange={onInputChange}
                                                                    onChangeSingle={(newValue: SingleValue<any>) => formik.setFieldValue('operationAgent', newValue.value)} defaultValue={{value: operationAgent?.id, label: operationAgent?.name}}/>
                                                                    {showErrors(formik, 'operationAgent')}
                                                                </FormGroup>
                                                                <div className={`d-flex align-items-end ${formik.values.operationAgent !== operationAgent?.id ? 'col-md-2' : 'd-none'}`}>
                                                                    <Tooltips title="Resetear a agente asignado">
                                                                        <Button
                                                                                color="danger"
                                                                                isLight
                                                                                icon="Clear"
                                                                                onClick={() => {
                                                                                    formik.setFieldValue('operationAgent', operationAgent?.id)
                                                                                    setRefreshOperationAgent( refreshOperationAgent + 1)
                                                                                }}
                                                                        />
                                                                    </Tooltips>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                    )
                                    : null 
                }

                <div className="col-12">
                    <div className="p-3 border rounded mb-3">
                        <div className="row">
                            <div className="col-md-12">
                                <h4>Comentario del contrato: </h4>
                            </div>
                            <div className="col-md-12 mb-2">
                                <Textarea id='operationComment' value={formik.values.operationComment} onChange={formik.handleChange} onBlur={formik.handleBlur} className={verifyClass(formik, 'operationComment')}></Textarea>
                                {showErrors(formik, 'operationComment')}
                            </div>  
                        </div>
                    </div>
                </div>      

                <div className="col-12">
                    <div className="p-3 border rounded">
                        <div className="row">
                            <div className="col-md-12">
                                <h4>Producto: </h4>
                            </div>
                            <div className="col-md-6 mb-2">
                                <div>
                                    <div className="row">
                                        <div className="col-md-4 mb-2">
                                            <FormGroup id='productType' label='Tipo de producto' requiredInputLabel>
                                                <Select id='productType' required ariaLabel='Seleccionar tipo de producto' placeholder='Elegir...' onChange={(e: any) => formik.setFieldValue('productType', e.target.value)} value={formik.values.productType} list={productTypeList} className={verifyClass(formik, 'productType')} />
                                                {showErrors(formik, 'productType')}
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <FormGroup id='productBusiness' label='Comercializadora' requiredInputLabel>
                                                <Select id='productBusiness' required ariaLabel='Seleccionar tipo de producto' placeholder='Elegir...' onChange={(e: any) => formik.setFieldValue('productBusiness', e.target.value)} value={formik.values.productBusiness} list={businessList.map((business: Business) => ({ label: business.name, value: business.id }))} className={verifyClass(formik, 'productBusiness')} />
                                                {showErrors(formik, 'productBusiness')}
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <FormGroup id='productConsumption' label='Consumo (Kw)'>
                                                <Input type="text" name="productConsumption" size={'sm'} value={formik.values.productConsumption} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                {showErrors(formik, 'productConsumption')}
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 mb-2">
                                            <FormGroup id='fee' label='Tarifa'>
                                                <Select id='productFee' ariaLabel='Seleccionar Tarifa' placeholder='Elegir...' onChange={(e: any) => formik.setFieldValue('productFee', e.target.value)} value={formik.values.productFee} list={feeList.map((fee: Fee) => ({ label: fee.name, value: fee.id }))} className={verifyClass(formik, 'productFee')} />
                                                {showErrors(formik, 'productFee')}
                                            </FormGroup>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-2">
                                <div>
                                    <div className="row">
                                        <div className="col-md-4 mb-2">
                                            <FormGroup id='productPowerP1' label='Potencia P1 (Kw)'>
                                                <Input type="text" name="productPowerP1" size={'sm'} className={verifyClass(formik, 'productPowerP1')} value={formik.values.productPowerP1} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                {showErrors(formik, 'productPowerP1')}
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <FormGroup id='productPowerP2' label='Potencia P2 (Kw)'>
                                                <Input type="text" name="productPowerP2" size={'sm'} className={verifyClass(formik, 'productPowerP2')} value={formik.values.productPowerP2} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                {showErrors(formik, 'productPowerP2')}
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <FormGroup id='productPowerP3' label='Potencia P3 (Kw)'>
                                                <Input type="text" name="productPowerP3" size={'sm'} className={verifyClass(formik, 'productPowerP3')} value={formik.values.productPowerP3} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                {showErrors(formik, 'productPowerP3')}
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 mb-2">
                                            <FormGroup id='productPowerP4' label='Potencia P4 (Kw)'>
                                                <Input type="text" name="productPowerP4" size={'sm'} className={verifyClass(formik, 'productPowerP4')} value={formik.values.productPowerP4} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                {showErrors(formik, 'productPowerP4')}
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <FormGroup id='productPowerP5' label='Potencia P5 (Kw)'>
                                                <Input type="text" name="productPowerP5" size={'sm'} className={verifyClass(formik, 'productPowerP5')} value={formik.values.productPowerP5} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                {showErrors(formik, 'productPowerP5')}
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <FormGroup id='productPowerP6' label='Potencia P6 (Kw)'>
                                                <Input type="text" name="productPowerP6" size={'sm'} className={verifyClass(formik, 'productPowerP6')} value={formik.values.productPowerP6} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                {showErrors(formik, 'productPowerP6')}
                                            </FormGroup>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <FormGroup id='product' label='Producto' requiredInputLabel>
                                    <Select id='product' ariaLabel='Seleccionar producto' placeholder='Elegir...' onChange={(e: any) => formik.setFieldValue('product', e.target.value)} value={formik.values.product} list={productList.map((product: Product) => ({ label: product.name + (product.points !== null  ? ` (${product.points} puntos)` : '') + (product.description ? ` - ${product.description}` : "") + (product.fee ? ` - ${product.fee.name}` : ""), value: product.id }))} className={verifyClass(formik, 'product')} />
                                    {showErrors(formik, 'product')}
                                </FormGroup>
                            </div>
                            <div className="col-md-6">
                                <FormGroup id='services' label='Servicios'>
                                    <ChecksGroup>
                                        {
                                            servicesList.map((service: ProductHasService) => (
                                                <Checks key={service.id}
                                                    label={(<>
                                                        {service.warning && (
                                                            <>
                                                                <Tooltips title={service.warning}>
                                                                    <Icon icon="Warning" className="me-2 text-warning" />
                                                                </Tooltips>
                                                            </>
                                                        )}
                                                        {service.service.name} {service.points !== null && `(${service.points} puntos)`}
                                                    </>)}
                                                    color="primary"
                                                    checked={formik.values.productServices.includes(service.service.id as never)}
                                                    onChange={(e: any) => {
                                                        if (e.target.checked) {
                                                            formik.setFieldValue('productServices', [...formik.values.productServices, service.service.id])
                                                        } else {
                                                            formik.setFieldValue('productServices', formik.values.productServices.filter((serviceId: string) => serviceId !== service.service.id))
                                                        }
                                                    }}
                                                    value={service.service.id} />
                                            ))
                                        }

                                    </ChecksGroup>
                                    {showErrors(formik, 'product')}
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 text-end">
                    <Button color="primary" className="mt-4" isDisable={loadingForm || loadingData} onClick={formik.handleSubmit}>
                        {loadingForm && (<Spinner />)}
                        {!loadingForm && (
                            <>
                                { operationId ? "Actualizar Contrato" : "Crear Contrato"}
                            </>
                        )}

                    </Button>
                </div>
            </div>


            {/* CNAE Selector modal */}
            <CNAEModalSelector
                visible={CNAESelectorVisible}
                setVisible={setCNAESelectorVisible}
                onSelect={(cnae: string) => {
                    formik.setFieldValue('operationCNAE', cnae);
                    setCNAESelectorVisible(false);
                }}
            ></CNAEModalSelector>
        </>
    )
}

export default CompleteOperationForm